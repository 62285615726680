import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

const getWrKeyContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_WR_KEY_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_WR_KEY_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

const getWrKeyContractAbi = () => {
  const metadata = require('../../../generated/contracts/WhiteRabbitKey.json');

  return metadata.abi as Array<AbiItem>;
};
export const WR_KEY_CONTRACT_ADDRESS = getWrKeyContractAddress(ETH_NETWORK);

export const WR_KEY_CONTRACT_ABI = getWrKeyContractAbi();
