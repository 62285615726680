import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

const getFactionNFTContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_FACTION_NFT_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_FACTION_NFT_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

const getFactionNFTContractAbi = () => {
  const metadata = require('../../../generated/contracts/FactionNFT.json');
  return metadata.abi as Array<AbiItem>;
};

export const FACTION_NFT_CONTRACT_ADDRESS =
  getFactionNFTContractAddress(ETH_NETWORK);

export const FACTION_NFT_CONTRACT_ABI = getFactionNFTContractAbi();
