import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

const getWrSteakContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_WR_STEAK_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../../generated/addresses/WhiteRabbitSteak.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getCookshopContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_COOKSHOP_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../../generated/addresses/CookShop.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getWrSteakContractAbi = () => {
  const metadata = require('../../../generated/contracts/WhiteRabbitSteak.json');

  return metadata.abi as Array<AbiItem>;
};

const getCookshopContractAbi = () => {
  const metadata = require('../../../generated/contracts/CookShop.json');

  return metadata.abi as Array<AbiItem>;
};

// Steak contract for airdropping
export const WR_STEAK_CONTRACT_ABI = getWrSteakContractAbi();
export const COOKSHOP_CONTRACT_ABI = getCookshopContractAbi();

export const WR_STEAK_CONTRACT_ADDRESS = getWrSteakContractAddress(ETH_NETWORK);
export const COOKSHOP_CONTRACT_ADDRESS =
  getCookshopContractAddress(ETH_NETWORK);
