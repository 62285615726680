import axios from 'axios';

type MetadataAttribute = {
  trait_type: string;
  value: string;
};

export type FactionMetaData = {
  name: string;
  collection: string;
  description: string;
  image: string;
  external_url: string;
  animation_url?: string;
  attributes: Array<MetadataAttribute>;
};

export const FactionToStateUrl: {[key: string]: string[]} = {
  fox: [
    'https://storage.googleapis.com/shibuya-white-rabbit/fox_0.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/fox_1.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/fox_2.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/fox_3.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/fox_4.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/fox_hween.gif',
  ],
  spirit: [
    'https://storage.googleapis.com/shibuya-white-rabbit/spirit_0.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/spirit_1.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/spirit_2.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/spirit_3.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/spirit_4.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/spirit_hween.gif',
  ],
  lady: [
    'https://storage.googleapis.com/shibuya-white-rabbit/lady_0.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/lady_1.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/lady_2.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/lady_3.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/lady_4.gif',
    'https://storage.googleapis.com/shibuya-white-rabbit/lady_hween.gif',
  ],
};

export const getFactionMetadata = async (
  id: number
): Promise<FactionMetaData> => {
  const res = await axios.get(`/api/factions/${id}`);

  return res.data;
};
