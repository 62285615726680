import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

const getWrTokenContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_WR_TOKEN_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getWrabDistributorContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_WRAB_DISTRIBUTOR_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_WRAB_DISTRIBUTOR_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

const getWrabDistributorV2ContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_WRAB_DISTRIBUTORV2_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_WRAB_DISTRIBUTORV2_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

const getWrTokenContractAbi = () => {
  const metadata = require('../../../generated/contracts/TestWhiteRabbitToken.json');

  return metadata.abi as Array<AbiItem>;
};

const getWrabDistributorContractAbi = () => {
  const metadata = require('../../../generated/contracts/WrabDistributor.json');
  return metadata.abi as Array<AbiItem>;
};

const getWrabDistributorV2ContractAbi = () => {
  const metadata = require('../../../generated/contracts/WrabDistributorV2.json');
  return metadata.abi as Array<AbiItem>;
};

export const WR_TOKEN_CONTRACT_ADDRESS = getWrTokenContractAddress(ETH_NETWORK);
export const WRAB_DISTRIBUTOR_CONTRACT_ABI = getWrabDistributorContractAbi();

export const WRAB_DISTRIBUTOR_CONTRACT_ADDRESS =
  getWrabDistributorContractAddress(ETH_NETWORK);

export const WR_TOKEN_CONTRACT_ABI = getWrTokenContractAbi();
export const WRAB_DISTRIBUTORV2_CONTRACT_ABI =
  getWrabDistributorV2ContractAbi();
export const WRAB_DISTRIBUTORV2_CONTRACT_ADDRESS =
  getWrabDistributorV2ContractAddress(ETH_NETWORK);
